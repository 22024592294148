export default [
  {
    categoryName: 'Dashboard',
    items: [
      {
        itemName: 'Dashboard',
        itemLink: '/admin/dashboard',
      },
    ],
  },
  {
    categoryName: 'Licencas',
    items: [
      {
        itemName: 'Licencas',
        itemLink: '/admin/licencas',
      },
    ],
  },
  {
    categoryName: 'Compras',
    items: [
      {
        itemName: 'Compras',
        itemLink: '/admin/compras',
      },
    ],
  },
]
